<template>
  <div class="receives animated fadeIn">
    <!-- <loading v-if="vLoading" /> -->
    <v-col cols="12">
      <v-card-title style="background-color: transparent !important">
        <v-row>
          <v-col>
            <h3>مخاطبین</h3>
          </v-col>
          <v-col>
            <v-btn
              @click="showNewContactDialog()"
              class="primary-btn"
              style="float: left"
            >
              <v-icon left>add</v-icon>
              <h6 class="mt-1">افزودن مخاطب</h6>
            </v-btn>
          </v-col>
        </v-row>
      </v-card-title>
      <v-card class="cards pa-3">
        <v-col class="pe-2">
          <div class="custom-input">
            <v-text-field
              class="float-end"
              outlined
              dense
              style="width: 330px"
              v-model="Filter"
              placeholder="نام مخاطب را جستجو کنید..."
            >
              <template #prepend-inner>
                <v-icon> $Magnify </v-icon>
              </template>
              <template v-if="Filter.length" #append>
                <v-icon @click="Filter = ''"> $Close </v-icon>
              </template>
            </v-text-field>
          </div>
        </v-col>
        <v-card-text>
          <div>
            <b-table
              responsive
              striped
              show-empty
              :fields="Fields"
              :items="contacts"
              empty-text="مخاطبی در سامانه به ثبت نرسیده‌است."
              empty-filtered-text="موردی برای نمایش وجود ندارد."
              :busy="contactsLoading"
              :filter="Filter"
              :current-page="CurrentPage"
              :per-page="PerPage"
              @filtered="onFiltered"
              class="secretariat-table"
              :filter-function="filterFn"
            >
              <template v-slot:head()="data">
                <div style="text-align: center; vertical-align: middle">
                  {{ data.label }}
                </div>
              </template>
              <template v-slot:cell()="data">
                <div style="text-align: center; vertical-align: middle">
                  {{ data.value ? data.value : "-" }}
                </div>
              </template>
              <template v-slot:cell(operation)="row">
                <div
                  style="text-align: center; vertical-align: middle"
                  class="pt-0"
                >
                  <v-btn
                    class="primary-btn pa-3"
                    @click="onRowSelected(row.item)"
                    height="42"
                  >
                    <v-icon class="me-2">mdi-eye</v-icon>
                    مشاهده
                  </v-btn>
                </div>
              </template>

              <template v-slot:cell(index)="data">
                <div style="text-align: center; vertical-align: middle">
                  {{ data.index + PerPage * (CurrentPage - 1) + 1 }}
                </div>
              </template>
              <div slot="table-busy" class="text-center primary--text my-2">
                <v-progress-circular
                  indeterminate
                  color="primary"
                  class="align-middle"
                ></v-progress-circular>
              </div>
            </b-table>
            <v-pagination
              v-model="CurrentPage"
              :length="Math.ceil(contacts.length / PerPage)"
              :total-visible="5"
              prev-icon="arrow_back"
              next-icon="arrow_forward"
              style="float: center"
            ></v-pagination>
            <v-select
              :menu-props="{ top: true, offsetY: true }"
              label="تعداد در هر صفحه:"
              style="width: 150px"
              v-model="PerPage"
              :items="perPageOptions"
              item-text="text"
              item-value="value"
            ></v-select>
          </div>
        </v-card-text>
      </v-card>
    </v-col>
    <!-- dialog for adding contact-->
    <v-dialog v-model="newContactDialog" width="600">
      <v-card class="modal-card">
        <v-card-title>
          <h4>ثبت مخاطب جدید</h4>
        </v-card-title>
        <v-card-text class="py-0 px-2">
          <v-container class="mt-2">
            <v-row>
              <v-col class="pb-0 custom-input">
                <v-text-field
                  outlined
                  dense
                  v-model="newContact.newContactName"
                >
                  <template v-slot:label>
                    <div class="d-flex flex-row">
                      <span class="red--text pe-1">*</span> نام رابط
                    </div>
                  </template>
                </v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col class="pb-0 mb-3 custom-input mb-3">
                <v-text-field
                  outlined
                  dense
                  dir="ltr"
                  label="نام انگلیسی رابط"
                  v-model="newContact.newContactNameEn"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col class="py-0 mb-3 custom-input mb-3">
                <v-text-field
                  outlined
                  dense
                  label="سمت"
                  v-model="newContact.newContactPosition"
                >
                  <template v-slot:label>
                    <div class="d-flex flex-row">
                      <span class="red--text pe-1">*</span> سمت
                    </div>
                  </template>
                </v-text-field>
              </v-col>
              <v-col class="py-0 custom-input mb-3">
                <v-text-field
                  outlined
                  dense
                  label="موبایل"
                  v-model="newContact.newMobile"
                  type="number"
                >
                  <template v-slot:label>
                    <div class="d-flex flex-row">
                      <span class="red--text pe-1">*</span> شماره موبایل
                    </div>
                  </template></v-text-field
                >
              </v-col>
            </v-row>
            <v-row>
              <v-col class="py-0 mb-3 custom-input mb-3">
                <v-text-field
                  outlined
                  dense
                  label="ایمیل"
                  v-model="newContact.newEmail"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col class="py-0 mb-3 custom-input mb-3">
                <v-text-field
                  outlined
                  dense
                  label="تلفن"
                  v-model="newContact.newPhone"
                  type="number"
                ></v-text-field>
              </v-col>
              <v-col class="py-0 mb-3 custom-input mb-3">
                <v-text-field
                  outlined
                  dense
                  label="تلفن 2"
                  v-model="newContact.newPhone2"
                  type="number"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col class="py-0 custom-input mb-3">
                <v-text-field
                  outlined
                  dense
                  label="تلفن داخلی"
                  v-model="newContact.newInternal"
                ></v-text-field>
              </v-col>
              <v-col class="py-0 mb-3 custom-input mb-3">
                <v-text-field
                  outlined
                  dense
                  label="فکس"
                  v-model="newContact.newFax"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col class="py-0 mb-3 custom-input mb-3">
                <v-text-field
                  outlined
                  dense
                  label="کد پستی"
                  v-model="newContact.newPostalCode"
                  type="number"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col class="py-0 mb-3 custom-input mb-3">
                <v-textarea
                  outlined
                  dense
                  label="آدرس"
                  rows="3"
                  v-model="newContact.newAddress"
                ></v-textarea>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions class="p-3 mr-3">
          <v-spacer></v-spacer>

          <v-btn
            class="primary-btn"
            @click="addContact()"
            :loading="addBusy"
            :disabled="
              !(
                newContact.newContactName &&
                newContact.newMobile &&
                newContact.newContactPosition
              )
            "
          >
            ثبت
          </v-btn>
          <v-btn class="text-white red-btn" @click="newContactDialog = false">
            لغو
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- show contact details and edit -->
    <v-dialog v-model="contactItemDialog" width="600">
      <v-card
        class="modal-card pa-3"
        style="max-height: 600px !important; overflow-y: auto"
        v-if="!edited"
      >
        <div v-if="gettingData" class="text-center">
          <v-progress-circular
            :size="70"
            :width="7"
            color="primary"
            indeterminate
          ></v-progress-circular>
          <h6 class="mt-8 primary--text text--darken-1">در حال بارگذاری...</h6>
        </div>
        <div v-else>
          <v-card-title class="mb-2">
            <h4>مشاهده اطلاعات مخاطب</h4>
            <v-spacer></v-spacer>
            <v-icon
              color="red"
              class="mb-2 ms-2"
              @click="
                contactItemDialog = false;
                edited = false;
              "
              >mdi-close</v-icon
            >
          </v-card-title>
          <v-card-text>
            <v-row class="mt-3">
              <v-col class="d-flex">
                <h6 class="h2 primary--text">نام رابط:</h6>
                <h6 class="h2 ms-2">
                  {{ selectedContact.name ? selectedContact.name : "-" }}
                </h6>
              </v-col>
              <v-col class="d-flex">
                <h6 class="h2 primary--text">نام انگلیسی رابط:</h6>
                <h6 class="h2 ms-2">
                  {{ selectedContact.nameEn ? selectedContact.nameEn : "-" }}
                </h6>
              </v-col>
            </v-row>
            <v-row class="mt-3">
              <v-col class="d-flex">
                <h6 class="h2 primary--text">سمت :</h6>
                <h6 class="h2 ms-2">
                  {{
                    selectedContact.position ? selectedContact.position : "-"
                  }}
                </h6>
              </v-col>
              <v-col class="d-flex">
                <h6 class="h2 primary--text">موبایل:</h6>
                <h6 class="h2 ms-2">
                  {{ selectedContact.mobile ? selectedContact.mobile : "-" }}
                </h6>
              </v-col>
            </v-row>
            <v-row class="mt-3">
              <v-col class="d-flex">
                <h6 class="h2 primary--text">ایمیل :</h6>
                <h6 class="h2 ms-2">
                  {{ selectedContact.email ? selectedContact.email : "-" }}
                </h6>
              </v-col>
            </v-row>
            <v-row class="mt-3">
              <v-col class="d-flex">
                <h6 class="h2 primary--text">تلفن 1 :</h6>
                <h6 class="h2 ms-2">
                  {{ selectedContact.phone ? selectedContact.phone : "-" }}
                </h6>
              </v-col>
              <v-col class="d-flex">
                <h6 class="h2 primary--text">تلفن 2 :</h6>
                <h6 class="h2 ms-2">
                  {{ selectedContact.phone2 ? selectedContact.phone2 : "-" }}
                </h6>
              </v-col>
            </v-row>
            <v-row class="mt-3">
              <v-col class="d-flex">
                <h6 class="h2 primary--text">تلفن داخلی :</h6>
                <h6 class="h2 ms-2">
                  {{
                    selectedContact.internalCode
                      ? selectedContact.internalCode
                      : "-"
                  }}
                </h6>
              </v-col>
              <v-col class="d-flex">
                <h6 class="h2 primary--text">تلفن فکس :</h6>
                <h6 class="h2 ms-2">
                  {{ selectedContact.fax ? selectedContact.fax : "-" }}
                </h6>
              </v-col>
            </v-row>
            <v-row class="mt-3">
              <v-col class="d-flex">
                <h6 class="h2 primary--text">کد پستی :</h6>
                <h6 class="h2 ms-2">
                  {{
                    selectedContact.postalCode
                      ? selectedContact.postalCode
                      : "-"
                  }}
                </h6>
              </v-col>
            </v-row>
            <v-row class="mt-3">
              <v-col class="d-flex">
                <h6 class="h2 primary--text">آدرس :</h6>
                <h6 class="h2 ms-2">
                  {{ selectedContact.address ? selectedContact.address : "-" }}
                </h6>
              </v-col>
            </v-row>
          </v-card-text>
          <v-btn
            @click="toggleToEdit()"
            class="primary-btn mb-4 me-5 pa-5 font-weight-bold"
          >
            <v-icon left>edit</v-icon>
            ویرایش</v-btn
          >
        </div>
      </v-card>

      <v-card class="modal-card" v-else>
        <v-card-title class="mb-2">
          <h4>ویرایش اطلاعات مخاطب</h4>
        </v-card-title>
        <v-card-text class="py-0 px-2">
          <v-container class="mt-2">
            <v-row>
              <v-col class="pb-0 custom-input">
                <v-text-field
                  outlined
                  dense
                  class="blue-input"
                  label="نام رابط"
                  v-model="selectedEdit.name"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col class="pb-0 custom-input mb-3">
                <v-text-field
                  outlined
                  dense
                  dir="ltr"
                  label="نام انگلیسی رابط"
                  v-model="selectedEdit.nameEn"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col class="py-0 custom-input mb-3">
                <v-text-field
                  outlined
                  dense
                  label="سمت"
                  v-model="selectedEdit.position"
                ></v-text-field>
              </v-col>
              <v-col class="py-0 custom-input mb-3">
                <v-text-field
                  outlined
                  dense
                  label="موبایل"
                  v-model="selectedEdit.mobile"
                  type="number"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col class="py-0 custom-input mb-3">
                <v-text-field
                  outlined
                  dense
                  label="ایمیل"
                  v-model="selectedEdit.email"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col class="py-0 custom-input mb-3">
                <v-text-field
                  outlined
                  dense
                  label="تلفن"
                  v-model="selectedEdit.phone"
                  type="number"
                ></v-text-field>
              </v-col>
              <v-col class="py-0 custom-input mb-3">
                <v-text-field
                  outlined
                  dense
                  label="تلفن 2"
                  v-model="selectedEdit.phone2"
                  type="number"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col class="py-0 custom-input mb-3">
                <v-text-field
                  outlined
                  dense
                  label="تلفن داخلی"
                  v-model="selectedEdit.internalCode"
                ></v-text-field>
              </v-col>
              <v-col class="py-0 custom-input mb-3">
                <v-text-field
                  outlined
                  dense
                  label="فکس"
                  v-model="selectedEdit.fax"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col class="py-0 custom-input mb-3">
                <v-text-field
                  outlined
                  dense
                  label="کد پستی"
                  v-model="selectedEdit.postalCode"
                  type="number"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col class="py-0 custom-input mb-3">
                <v-textarea
                  outlined
                  dense
                  label="آدرس"
                  rows="3"
                  v-model="selectedEdit.address"
                ></v-textarea>
              </v-col>
            </v-row>
            <v-row class="d-flex justify-content-end me-0">
              <div>
                <v-btn
                  @click="editContact()"
                  :loading="editBusy"
                  class="primary-btn mt-4 mx-2"
                  >ثبت</v-btn
                >
                <v-btn @click="edited = false" class="red-btn mt-4">لغو</v-btn>
              </div>
            </v-row>
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import axios from "axios";
export default {
  data() {
    return {
      newContact: {},
      newContactDialog: false,
      contactItemDialog: false,
      edited: false,
      selectedContact: {},
      Fields: [
        { key: "index", label: "#" },
        { key: "name", label: "نام" },
        { key: "position", label: "سمت" },
        { key: "mobile", label: "موبایل" },
        { key: "operation", label: "عملیات" },
      ],
      contacts: [],
      CurrentPage: 1,
      PerPage: 10,
      TotalRows: null,
      perPageOptions: [
        { value: 10, text: "10" },
        { value: 25, text: "25" },
        { value: 50, text: "50" },
        { value: 100, text: "100" },
      ],
      Filter: "",
      Busy: false,
      contactsLoading: false,
      selectedEdit: {},
      selectedId: "",
      gettingData: false,
      editBusy: false,
      addBusy: false,
    };
  },
  methods: {
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.TotalRows = filteredItems.length;
      this.CurrentPage = 1;
    },
    showNewContactDialog() {
      this.newContact = {};
      this.newContactDialog = true;
    },
    onRowSelected(item) {
      this.gettingData = true;
      this.selectedId = item.id ? item.id : this.selectedId;
      this.$http
        .get(
          this.baseUrl +
            "/clinic/secretariat/contacts/getData/" +
            this.selectedId,
          {
            headers: {
              Authorization: "Bearer: " + localStorage.getItem("token"),
            },
          }
        )
        .then((res) => {
          this.gettingData = false;
          if (res.status == 200) {
            this.selectedContact = res.data;
            this.edited = false;
            this.contactItemDialog = true;
          } else {
            this.toast("خطا: مشکلی پیش آمده. مجددا امتحان کنید.", "error");
          }
        })
        .catch((err) => {
          this.toast("خطا: " + err.response.data, "error");
          this.gettingData = false;
        });
    },
    toggleToEdit() {
      this.selectedEdit = JSON.parse(JSON.stringify(this.selectedContact));
      this.edited = true;
    },
    addContact() {
      this.newContactDialog = false;
      this.addBusy = true;
      axios
        .post(
          this.baseUrl + "/clinic/hr/employee/newContact",
          {
            role: "contact",
            name: this.newContact.newContactName,
            nameEn: this.newContact.newContactNameEn,
            internalCode: this.newContact.newInternal,
            position: this.newContact.newContactPosition,
            phone: this.newContact.newPhone,
            phone2: this.newContact.newPhone2,
            fax: this.newContact.newFax,
            mobile: this.newContact.newMobile,
            email: this.newContact.newEmail,
            address: this.newContact.newAddress,
            postalCode: this.newContact.newPostalCode,
          },
          {
            headers: {
              Authorization: "Bearer: " + localStorage.getItem("token"),
            },
          }
        )
        .then((res) => {
          this.addBusy = false;
          if (res.status == 201) {
            this.toast(res.data, "success");
            this.getContacts();
          } else {
            this.toast("خطا: مشکلی پیش آمده. مجددا امتحان کنید.", "error");
          }
        })
        .catch((err) => {
          this.toast(err.response.data, "error");
          this.addBusy = false;
        });
    },
    editContact() {
      this.editBusy = true;
      this.$http
        .post(
          this.baseUrl + "/clinic/hr/employee/edit",
          {
            userId: this.selectedId,
            role: "contact",
            name: this.selectedEdit.name,
            nameEn: this.selectedEdit.nameEn,
            internalCode: this.selectedEdit.internalCode,
            position: this.selectedEdit.position,
            phone: this.selectedEdit.phone,
            phone2: this.selectedEdit.phone2,
            fax: this.selectedEdit.fax,
            mobile: this.selectedEdit.mobile,
            email: this.selectedEdit.email,
            address: this.selectedEdit.address,
            postalCode: this.selectedEdit.postalCode,
          },
          {
            headers: {
              Authorization: "Bearer: " + localStorage.getItem("token"),
            },
          }
        )
        .then((res) => {
          this.editBusy = false;
          if (res.status == 200) {
            const title = (
              <div>
                <h5>ویرایش مخاطب</h5>
                <span>.مخاطب با موفقیت ویرایش شد </span>
              </div>
            );
            this.toast(title, "success");
            this.edited = false;
            this.onRowSelected(this.selectedEdit);
            this.getContacts();
          } else {
            this.toast("خطا: مشکلی پیش آمده. مجددا امتحان کنید.", "error");
            this.edited = false;
          }
        })
        .catch((err) => {
          this.toast("خطا: " + err.response.data, "error");
          this.edited = false;
          this.editBusy = false;
        });
    },
    getContacts() {
      this.contactsLoading = true;
      axios
        .get(this.baseUrl + "/clinic/secretariat/contacts/getAllContacts", {
          headers: {
            Authorization: "Bearer: " + localStorage.getItem("token"),
          },
        })
        .then((res) => {
          this.contacts = res.data;
          setTimeout(() => {
            this.contactsLoading = false;
          }, 500);
        })
        .catch((err) => {
          this.toast(err.response.data, "error");
        });
    },
    filterFn(item, Filter) {
      if (item.name.includes(Filter)) {
        return true;
      } else {
        return false;
      }
    },
  },
  mounted() {
    this.getContacts();
  },
};
</script>

<style lang="scss">
.v-dialog:not(.v-dialog--fullscreen) {
  max-height: 75%;
}
</style>
